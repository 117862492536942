import React from "react"
import "resources/style/style.less"
import Seo from "../components/seo"
import Layout from "components/layout"
import ComingSoonPage from "components/section/coming-soon"

const IndexPage = () => {
  return (
    <>
      <Seo title={"Wispay FAQ"} />
      <Layout headerVariant="black" headerBackground="blue">
        <ComingSoonPage />
      </Layout>
    </>
  )
}

export default IndexPage
