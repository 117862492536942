import React from "react"
import { Col, Typography } from "antd"
import { StaticImage } from "gatsby-plugin-image"

const ComingSoonPage = () => {
  return (
    <div className="base-container-full-screen">
      <StaticImage
        src={"../../resources/img/faq/top-coming-soon.png"}
        alt="top coming soon"
        placeholder="none"
        className="img-coming-soon"
      />
      <Col xl={6} lg={10} md={14} sm={16} xs={20} className="text-full-screen">
        <Typography.Text className="text-title">
          Kami Sedang Menyiapkan {""}
          <Typography.Paragraph style={{ fontWeight: "normal", margin: 0 }}>
            sesuatu yang baru.
          </Typography.Paragraph>
        </Typography.Text>
        <Typography.Text className="text-desc">
          Agar anda bisa lebih produktif.
        </Typography.Text>
      </Col>
      <StaticImage
        className="shape-right-full-screen"
        placeholder="none"
        src={"../../resources/img/shape/round-shape-2.png"}
        alt="shape-right"
      />
      <StaticImage
        className="shape-left-full-screen"
        placeholder="none"
        src={"../../resources/img/shape/round-shape-1.png"}
        alt="shape left"
      />
    </div>
  )
}

export default ComingSoonPage
